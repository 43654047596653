import { FunctionComponent, useState, useEffect, useMemo } from 'react'
import {
  useStyles,
  Wrapper,
  TopContainer,
  GridContainer,
  LeftContainer,
  RightContainer,
} from './styled'
import { InterestType, Investment } from 'types'
import DataGrid from 'components/common/DataGrid'
import {
  columnsInvestmentSimpleInterestStatements,
  columnsInvestmentStatements,
} from 'components/common/DataGrid/utils'
import moment from 'moment-timezone'
import { useMutation, useLazyQuery } from '@apollo/client'
import {
  DOWNLOAD_STATEMENTS_PDF_BY_INVESTOR,
  GET_INVESTMENT_DETAILS_STATEMENTS_BY_INVESTOR,
} from '../queries'
import { Button, CircularProgress, MenuItem } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { ReactComponent as DownloadIcon } from 'assets/download.svg'
import { FormattedMessage, useIntl } from 'react-intl'
import SelectInput from 'components/common/Inputs/SelectInput'
import { getMonths } from 'utils/Statements'
import { NEW_YORK_TIMEZONE } from 'utils/DateUtils'

interface Props {
  investment: Investment
}

const StatementsTab: FunctionComponent<Props> = ({ investment }: Props) => {
  const [selectedMonth, setSelectedMonth] = useState('')
  const [disableDownloadPdf, setDisableDownloadPdf] = useState(true)

  const columns =
    investment.plan.interestType === InterestType.SIMPLE
      ? columnsInvestmentSimpleInterestStatements()
      : columnsInvestmentStatements()

  const classes = useStyles()
  const intl = useIntl()

  const [getStatements, { data, loading }] = useLazyQuery(
    GET_INVESTMENT_DETAILS_STATEMENTS_BY_INVESTOR,
    {
      fetchPolicy: 'no-cache',
      variables: {
        investmentUuid: investment?.uuid,
        monthDate: moment.tz(selectedMonth, NEW_YORK_TIMEZONE).utc().format(),
        profileUuid: investment?.profile.uuid,
      },
    },
  )

  const [statementsPdf] = useMutation(DOWNLOAD_STATEMENTS_PDF_BY_INVESTOR, {
    variables: {
      investmentUuid: investment?.uuid,
      monthDate: moment.tz(selectedMonth, NEW_YORK_TIMEZONE).utc().format(),
      profileUuid: investment?.profile.uuid,
    },
  })

  useEffect(() => {
    if (data) setDisableDownloadPdf(false)
  }, [data, loading])

  useEffect(() => {
    if (selectedMonth !== '' && !loading) getStatements()
  }, [selectedMonth])

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedMonth(event.target.value)
    getStatements()
  }

  const handleDownloadPdf = async () => {
    setDisableDownloadPdf(true)
    try {
      const pdf = await statementsPdf()

      if (pdf) {
        const downloadLink = document.createElement('a')
        downloadLink.href =
          'data:application/octet-stream;base64,' + pdf?.data?.downloadStatementPdfByInvestor
        downloadLink.download = `${investment?.plan?.name} Plan - ${moment
          .tz(selectedMonth, NEW_YORK_TIMEZONE)
          .format('MMM YYYY')}.pdf`
        downloadLink.click()
      }
    } catch (e: unknown) {
      // TODO error handler
    } finally {
      getStatements()
      setDisableDownloadPdf(false)
    }
  }

  const availableMonths = useMemo(() => {
    return getMonths({
      startDate: investment.duration.startDate,
      closedDate: investment.duration.closedDate,
      stoppedDate: investment.duration.stoppedDate,
    })
  }, [
    investment.duration.startDate,
    investment.duration.closedDate,
    investment.duration.stoppedDate,
  ])

  useEffect(() => {
    if (availableMonths.length > 0 && selectedMonth === '') {
      setSelectedMonth(availableMonths[availableMonths.length - 1])
    }
  }, [availableMonths])

  return (
    <Wrapper>
      <TopContainer>
        <LeftContainer>
          <SelectInput
            value={selectedMonth}
            label={intl.formatMessage({
              id: 'screen.investmentDetail.tab2.dataGrid.selectedMonth',
            })}
            onChange={handleChange}
            renderValue={(selected) => moment.tz(selected, NEW_YORK_TIMEZONE).format('MMM[,] YYYY')}
          >
            {availableMonths.map((month, index) => (
              <MenuItem
                key={`${index}${month}`}
                value={moment.tz(month, NEW_YORK_TIMEZONE).format()}
              >
                {moment.tz(month, NEW_YORK_TIMEZONE).format('MMM YYYY')}
              </MenuItem>
            ))}
          </SelectInput>
        </LeftContainer>
        <RightContainer>
          <Button
            variant="text"
            className={classes.downloadPDFButton}
            endIcon={
              disableDownloadPdf && !loading ? (
                <CircularProgress size="20px" thickness={8} />
              ) : (
                <DownloadIcon className={classes.icon} />
              )
            }
            onClick={handleDownloadPdf}
            disabled={!investment || disableDownloadPdf}
          >
            {disableDownloadPdf && !loading ? (
              <FormattedMessage id="screen.investmentDetail.tab2.button.downloading" />
            ) : (
              <FormattedMessage id="screen.investmentDetail.tab2.button.DownloadPDF" />
            )}
          </Button>
        </RightContainer>
      </TopContainer>
      <GridContainer>
        <DataGrid
          className={classes.dataGrid}
          columns={columns}
          rows={data?.statementsByInvestor?.statements}
          emptyMessageId="screen.depositRequests.search.empty"
          errorMessageId="screen.depositRequests.tab.error"
          isLoading={loading}
          initialState={{
            sorting: {
              sortModel: [{ field: 'date', sort: 'asc' }],
            },
          }}
          autoHeight
        />
      </GridContainer>
    </Wrapper>
  )
}

export default StatementsTab
