import { InvestmentStatus, TransactionStatus } from 'types'

type colors =
  | 'default'
  | 'warning'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | undefined

export const colorInvestmentStatus = {
  [InvestmentStatus.draft]: 'warning' as colors,
  [InvestmentStatus.in_transit]: 'warning' as colors,
  [InvestmentStatus.active]: 'success' as colors,
  [InvestmentStatus.pending]: 'default' as colors,
  [InvestmentStatus.rejected]: 'error' as colors,
  [InvestmentStatus.closing]: 'warning' as colors,
  [InvestmentStatus.closed]: 'default' as colors,
  [InvestmentStatus.stopped]: 'default' as colors,
}

export const colorTransactionStatus = {
  [TransactionStatus.completed]: 'success' as colors,
  [TransactionStatus.pending]: 'warning' as colors,
  [TransactionStatus.canceled]: 'error' as colors,
  [TransactionStatus.active]: 'default' as colors,
  [TransactionStatus.schedule]: 'warning' as colors,
}
