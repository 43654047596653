import moment from 'moment-timezone'

const TIMEZONE = 'America/New_York'

export const getMonths = (data: {
  startDate?: string
  closedDate?: string
  stoppedDate?: string
}) => {
  const { closedDate, startDate, stoppedDate } = data

  if (!startDate) return []
  const availableMonths: string[] = []
  const startDateAux = moment.tz(startDate, TIMEZONE)
  const today = moment.tz(TIMEZONE)

  const cutoffDate =
    closedDate || stoppedDate
      ? moment.tz(closedDate || stoppedDate, TIMEZONE).set({ seconds: 0 })
      : today

  while (startDateAux.isSameOrBefore(cutoffDate, 'month')) {
    availableMonths.push(startDateAux.format())
    startDateAux.add(1, 'months')
  }
  return availableMonths
}
