import { Profile } from 'global/types'

export type ObjectValues<T> = T[keyof T]

export type AnimalResponse = {
  message: string
  status: string
}

export const DepositFrequency = {
  NEXT_MONTH: 'NEXT_MONTH',
  NEXT_MONDAY: 'NEXT_MONDAY',
} as const

export type DepositFrequency = ObjectValues<typeof DepositFrequency>

export const InterestType = {
  COMPOUND: 'COMPOUND',
  SIMPLE: 'SIMPLE',
} as const

export type InterestType = ObjectValues<typeof InterestType>

export type Plan = {
  uuid?: string
  name: string
  suffixName?: string
  description: string
  shortDescription: string
  interestRatePerMonth: number
  principalLockupInMonths: number
  minimumInitialInvestment: number
  maximumInvestment?: number
  maturity: number
  autoRenewal: boolean
  autoRenewalPeriodInMonths: number
  maxAutoRenewalPeriod?: number
  renewalPeriodLockInMonths: number
  depositAllowed: boolean
  earlyWithdrawalAllowed: boolean
  earlyWithdrawalPenalty: number
  earningPenalty: number
  initialProcessingFee: number
  scheduledWithdrawalAllowed: boolean
  withdrawalAllowed: boolean
  depositFrequency: DepositFrequency
  deadlineDays: number
  deadlineTime: string
  includeWeekends: boolean
  interestType: InterestType
  earlyCancellationAllowed: boolean
  earlyCancellationPenalty: number
  scheduledWithdrawalMandatory: boolean
  scheduledWithdrawalPeriodInMonths: number
  interestPeriodDescription: string
  contractId: string
  memorandumId: string
  nextPlanUuid?: string
  previousPlanUuid?: string
}

export enum InvestmentStatus {
  draft = 'DRAFT',
  pending = 'PENDING',
  active = 'ACTIVE',
  rejected = 'REJECTED',
  in_transit = 'IN_TRANSIT',
  closed = 'CLOSED',
  closing = 'CLOSING',
  stopped = 'STOPPED',
}

export enum TransactionStatus {
  completed = 'COMPLETED',
  pending = 'PENDING',
  canceled = 'CANCELED',
  active = 'ACTIVE',
  schedule = 'SCHEDULE',
}

export type Investment = {
  uuid: string
  status: InvestmentStatus
  isMonthlyWithdrawal: boolean
  isInLockupPrincipal?: boolean
  profile: Profile
  duration: Duration
  plan: Plan
  contract: Contract
  memorandum: Contract
  balance: Balance
  initialAmount?: number
  amount: number
  transactions: Transaction[]
  scheduledWithdrawals?: ScheduledWithdrawal[] | null
}

export type Duration = {
  startDate: string
  endDate: string
  closedDate?: string
  stoppedDate?: string
}

export type Contract = {
  id: string
  url: string
  signUrl: string
}

export type Balance = {
  cutoffDate: Date
  earningPercentage: number
  total: number
}

export enum TransactionTypeNames {
  deposit = 'Deposit',
  withdrawal = 'Withdrawal',
  earning = 'Earning',
}

export type Transaction = {
  acknowledgeAt: Date
  createdAt?: Date
  amount: number
  approvedAt: Date
  status: string
  stripeId: string
  uuid: string
  id: number
  canBeCancelable?: boolean
  requestedAt?: Date
  reference?: number
  __typename: TransactionTypeNames
}

export type Bank = {
  uuid: string
  plaidId: string
  name: string
  logo: string
}

export type GridDataRow = {
  [key: string]: any
}

export enum DepositStatus {
  IN_TRANSIT,
  PENDING,
  ACTIVE,
}

export type Deposit = {
  id: string
  fullName: string
  investment: Investment
  deposit: DepositTransaction
}

export type DepositTransaction = {
  uuid: string
  type: string
  status: DepositStatus
  stripeId: string
  amount: number
  acknowledgeAt: string
  approvedAt: string
  createdAt: string
  reference: number
}

export type DowngradePlan = {
  downgrade: boolean
  interestRate: number
  toPlan: string
}

export type WithdrawalRequestValidator = {
  amount: number
  downgradePlan: DowngradePlan
  earningPenalization: number
  penalty: number
  totalDeposit: number
}

export enum WithdrawalStatus {
  pending = 'PENDING',
  cancelled = 'CANCELED',
  active = 'ACTIVE',
}

export enum WithdrawalType {
  REGULAR = 'REGULAR',
  SCHEDULED = 'SCHEDULED',
}

export type Withdrawal = {
  amount: number
  deliveredAt: string
  approvedAt: string
  canBeCancelable: boolean
  requestedAt: string
  status: WithdrawalStatus
  type: WithdrawalType
  uuid: string
}

export type WithdrawalRequest = {
  fullName: string
  id: number
  investment: Investment
  withdrawal: Withdrawal
}

export type DocusignUrlResponse = {
  url: string
  signUrl: string
  envelopeId: string
}

export enum DepositType {
  WIRE_TRANSFER = 'WIRE_TRANSFER',
  STRIPE = 'STRIPE',
}

export type Address = {
  addressOne: string
  addressTwo: string
  city: string
  country: string
  postalCode: string
  state: string
  uuid: string
}

export type ScheduledWithdrawal = {
  uuid: string
  amount: number
  endDate?: string | null
  startDate: string
  status: ScheduledWithdrawalStatus
}

export enum ScheduledWithdrawalStatus {
  ACTIVE = 'ACTIVE',
  FINALIZED = 'FINALIZED',
}
