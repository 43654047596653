/* eslint-disable @typescript-eslint/no-explicit-any */
import { InvestmentStatus } from './../types/index'
import type { FormatXMLElementFn } from 'intl-messageformat'

export const enum ProfileVerificationStatus {
  pending = 'IN_REVIEW',
  rejected = 'REJECTED',
  approved = 'APPROVED',
  completed = 'COMPLETED',
  created = 'CREATED',
  declined = 'DECLINED',
}

export type User = {
  email: string
  name: string
  lastName: string
  roles: string[]
  userId: string
}

export type Profile = {
  uuid: string
  application: Application
  bankAccounts: BankAccount[]
  referralCode: ReferralCode
  tin?: string
  user?: User | null
  endingBalance?: number
}

export type ReferralCode = {
  uuid: string
  code: string
  active: boolean
  referralBalance: number
  isUpdateBlocked?: boolean
}

export type Application = {
  seenAt: boolean
  name: string
  applicantId: string
  levelName: string
  status: ProfileVerificationStatus
}

export type Bank = {
  uuid: string
  plaidId: string
  name: string
  logo: string
}

export type BankAccount = {
  uuid: string
  plaidId: string
  plaidToken: string
  plaidMetaData: string
  bank: Bank
}

export enum StepNumber {
  NONE,
  FIRST,
  SECOND,
  THIRD,
}

export enum StepsWithdrawalRequest {
  AMOUNT_STEP,
  CONFIRMATION_STEP,
  REQUESTED_STEP,
}

export enum StepsScheduleWithdrawal {
  AMOUNT_STEP,
  CONFIRMATION_STEP,
  REQUESTED_STEP,
}

export enum StepperScheduleWithdrawal {
  SCHEDULE_WITHDRAWAL_AMOUNT = 'SCHEDULE_WITHDRAWAL_AMOUNT',
  SCHEDULE_WITHDRAWAL_CONFIRMATION = 'SCHEDULE_WITHDRAWAL_CONFIRMATION',
}

export enum StepperNewInvestment {
  INVESTMENT_PLAN = 'INVESTMENT_PLAN',
  CONTRACTS_SIGNING = 'CONTRACTS_SIGNING',
  INVESTMENT_FUNDING = 'INVESTMENT_FUNDING',
}

export enum StepperWithdrawalRequest {
  WITHDRAWAL_AMOUNT = 'WITHDRAWAL_AMOUNT',
  WITHDRAWAL_CONFIRMATION = 'WITHDRAWAL_CONFIRMATION',
}

export enum StepperNewDepositRequest {
  DEPOSIT_AMOUNT = 'DEPOSIT_AMOUNT',
  DEPOSIT_CONFIRMATION = 'DEPOSIT_CONFIRMATION',
}

export enum DepositRequestsTab {
  REQUESTED = 'REQUESTED',
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
}

export enum WithdrawalRequestsTab {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  ACTIVE_SCHEDULED = 'ACTIVE_SCHEDULED',
  INACTIVE_SCHEDULED = 'INACTIVE_SCHEDULED',
}

export enum InvestmentsListTab {
  ACTIVE = InvestmentStatus['active'],
  IN_TRANSIT = InvestmentStatus['in_transit'],
  DRAFT = InvestmentStatus['draft'],
  STOPPED = InvestmentStatus['stopped'],
  CLOSING = InvestmentStatus['closing'],
  CLOSED = InvestmentStatus['closed'],
}

export enum InvestmentDetailsTab {
  OVERVIEW = 'OVERVIEW',
  STATEMENTS = 'STATEMENTS',
  ALL_ACTIVITY = 'ALL_ACTIVITY',
  DEPOSITS = 'DEPOSITS',
  WITHDRAWALS = 'WITHDRAWALS',
  INTEREST_EARNED = 'INTEREST_EARNED',
  SCHEDULE_WITHDRAWALS = 'SCHEDULE_WITHDRAWALS',
}

export enum AgentDetailsTab {
  INVESTMENTS = 'INVESTMENTS',
  INVESTORS = 'INVESTORS',
  INVESTORS_INVESTMENTS = 'INVESTORS_INVESTMENTS',
  PLANS = 'PLANS',
}

export enum AgentMyInvestmentTab {
  MY_INVESTMENTS = 'MY_INVESTMENTS',
  MY_INVESTORS_INVESTMENTS = 'MY_INVESTORS_INVESTMENTS',
}

export enum FundInvestmentTabs {
  // TODO: ENABLE LINK_BANK,
  WIRE_TRANSFER,
}

export type IntlValuesObject = Record<
  string,
  | string
  | number
  | boolean
  | Date
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | Iterable<React.ReactNode>
  | React.ReactPortal
  | FormatXMLElementFn<React.ReactNode, React.ReactNode>
  | null
  | undefined
>

export enum WireTransferButton {
  ABA_NUMBER,
  ACH_NUMBER,
  ACCOUNT_NUMBER,
  SWIFT_CODE,
  REFERENCE_NUMBER,
}

export enum ProfileTabs {
  BANK_ACCOUNT,
  ADDRESS,
}
