import { gql } from '@apollo/client'

export const GET_INVESTMENT_DETAILS_CHART_DATA_BY_INVESTOR = gql`
  query InvestmentDetailsChartByInvestor(
    $startDate: String!
    $endDate: String!
    $investmentUuid: String!
    $profileUuid: String!
    $timezone: String
  ) {
    investmentDetailsChartByInvestor(
      startDate: $startDate
      endDate: $endDate
      investmentUuid: $investmentUuid
      profileUuid: $profileUuid
      timezone: $timezone
    ) {
      totalDeposits
      totalEarnings
      totalWithdrawals
      totalLoss
      chartData {
        labels
        datasets {
          label
          data
          backgroundColor
        }
      }
    }
  }
`

export const GET_INVESTMENT_DETAILS_STATEMENTS_BY_INVESTOR = gql`
  query StatementsByInvestor($investmentUuid: String!, $monthDate: String!, $profileUuid: String!) {
    statementsByInvestor(
      investmentUuid: $investmentUuid
      monthDate: $monthDate
      profileUuid: $profileUuid
    ) {
      total
      statements {
        id
        transaction
        amount
        balance
        days
        approxEarnings
        cumulativeApproxEarnings
        date
      }
    }
  }
`

export const DOWNLOAD_STATEMENTS_PDF_BY_INVESTOR = gql`
  mutation Mutation($investmentUuid: String!, $profileUuid: String!, $monthDate: String!) {
    downloadStatementPdfByInvestor(
      investmentUuid: $investmentUuid
      profileUuid: $profileUuid
      monthDate: $monthDate
    )
  }
`

export const GET_INVESTMENT_BY_UUID = gql`
  query investmentByUuid($uuid: String!) {
    investmentByUuid(uuid: $uuid) {
      uuid
      status
      isMonthlyWithdrawal
      profile {
        uuid
        metaData
        application {
          applicantId
          levelName
          status
          metaData
          name
          seenAt
        }
        identity {
          plaidId
          plaidToken
        }
        bankAccounts {
          uuid
          plaidId
          plaidToken
          plaidMetadata
          bank {
            uuid
            plaidId
            name
            logo
          }
        }
      }
      duration {
        startDate
        endDate
        closedDate
        stoppedDate
      }
      contract {
        id
        url
      }
      plan {
        uuid
        name
        suffixName
        description
        interestRatePerMonth
        minimumInitialInvestment
        principalLockupInMonths
        maturity
        withdrawalAllowed
        earlyCancellationPenalty
        scheduledWithdrawalAllowed
        earlyCancellationAllowed
        interestPeriodDescription
        interestType
      }
      transactions {
        ... on Deposit {
          uuid
          status
          stripeId
          amount
          acknowledgeAt
          approvedAt
          createdAt
          reference
        }
        ... on Earning {
          uuid
          amount
          approvedAt
        }
        ... on Withdrawal {
          uuid
          status
          canBeCancelable
          amount
          requestedAt
          approvedAt
          type
        }
      }
      balance {
        earningPercentage
        total
        cutoffDate
      }
      scheduledWithdrawals {
        uuid
        amount
        status
        startDate
        endDate
      }
    }
  }
`
