import { Tabs as MuiTabs, Grid, Typography, useMediaQuery, Tab } from '@mui/material'
import DataGrid from 'components/common/DataGrid'
import { columnsTreasurerInvestments } from 'components/common/DataGrid/utils'
import SearchInput from 'components/common/Inputs/SearchInput'
import {
  SearchBarWrapper,
  TabContentHeaderWrapper,
  TabContentWrapper,
  useStyles,
  MainDataGridWrapper,
} from 'layouts/Treasurer/styled'
import { useCallback, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Investment } from 'types'
import { useQuery } from '@apollo/client'
import { OVERALL_INVESTMENT_LIST } from './queries'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { GridRowParams, GridSortDirection, GridSortModel } from '@mui/x-data-grid'
import { Theme } from '@mui/material/styles'
import { theme } from 'screens/App/theme'
import { TabsWrapper } from './styled'
import { InvestmentsTab, changeTab } from './reducer'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalState } from 'redux/store'
import { InvestmentsListTab } from 'global/types'

interface OperationVariables {
  page?: number
  limit?: number
  name?: string
  orderBy?: string
  orderDirection?: GridSortDirection
  status?: InvestmentsListTab
}

interface OverallInvestments {
  overallInvestments: {
    investments: Investment[]
    total: number
  }
}

const getAriaAndId = (tab: InvestmentsListTab) => {
  return {
    id: `investment-list-tab-${tab}`,
    'aria-controls': `investment-list-tabpanel-${tab}`,
  }
}

const defaultVariables: OperationVariables = {
  page: 0,
  limit: 10,
  name: '',
  orderBy: 'investment_startDate',
  orderDirection: 'desc',
  status: InvestmentsListTab.ACTIVE,
}

function InvestmentList() {
  const classes = useStyles()
  const navigate = useNavigate()
  const intl = useIntl()
  const isBreakpointMdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))

  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [name, setName] = useState('')
  const [orderBy, setOrderBy] = useState<string | undefined>(undefined)
  const [orderDirection, setOrderDirection] = useState<GridSortDirection | undefined>(undefined)

  const selectedTab: InvestmentsTab = useSelector((state: GlobalState) => state.investmentList)
  const dispatch = useDispatch()

  const { loading, data, error, refetch } = useQuery<OverallInvestments, OperationVariables>(
    OVERALL_INVESTMENT_LIST,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        ...defaultVariables,
      },
    },
  )

  const refetchWithDebounce = useCallback(_.debounce(refetch, 1000), [])

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    dispatch(changeTab(newValue))
    const newStatus = Object.values(InvestmentsListTab)[newValue] as InvestmentsListTab
    setPage(0)
    setOrderBy('investment_startDate')
    setOrderDirection('desc')
    setName('')
    refetch({ ...defaultVariables, status: newStatus })
  }

  const handleChangeSearchInput = (newSearch: string) => {
    setName(newSearch)
    if (newSearch.length === 1) return
    setPage(0)
    refetchWithDebounce({ page: 0, name: newSearch })
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
    refetch({ page: newPage })
  }

  const handleChangePageSize = (newLimit: number) => {
    setLimit(newLimit)
    setPage(0)
    refetch({ page: 0, limit: newLimit })
  }

  const handleSortModelChange = (sortModel: GridSortModel) => {
    const newOrderBy = sortModel?.[0]?.field
    const newOrderDirection = sortModel?.[0]?.sort
    setPage(0)
    setOrderBy(newOrderBy)
    setOrderDirection(newOrderDirection)
    refetch({ page: 0, orderBy: newOrderBy, orderDirection: newOrderDirection })
  }

  const onRowClick = (row: GridRowParams) => {
    navigate('/investment-detail/' + row.id)
  }

  const columns = columnsTreasurerInvestments()

  let investments = data?.overallInvestments.investments ?? []
  let total = data?.overallInvestments.total ?? 0
  if (error || loading) {
    investments = []
    total = 0
  }

  return (
    <TabContentWrapper>
      <TabContentHeaderWrapper>
        <Grid
          container
          rowGap={theme.spacing(5)}
          alignItems={isBreakpointMdUp ? 'center' : 'initial'}
          justifyContent={isBreakpointMdUp ? 'space-between' : 'initial'}
          paddingBottom={{ xs: 2, md: 2.5 }}
        >
          <Grid item xs={12} md="auto">
            <Typography variant={isBreakpointMdUp ? 'h2' : 'h3'} className={classes.white}>
              <FormattedMessage id="screen.investmentList.title" />
            </Typography>
          </Grid>
          <Grid item xs={12} md="auto">
            <SearchBarWrapper>
              <SearchInput
                fullWidth={!isBreakpointMdUp}
                value={name}
                onChangeValue={handleChangeSearchInput}
              />
            </SearchBarWrapper>
          </Grid>
        </Grid>
      </TabContentHeaderWrapper>
      <TabsWrapper>
        <MuiTabs
          className={classes.tabs}
          value={selectedTab.index}
          onChange={handleChange}
          aria-label="Investment List"
        >
          <Tab
            label={intl.formatMessage({ id: 'screen.investmentList.active' })}
            {...getAriaAndId(InvestmentsListTab.ACTIVE)}
          />
          <Tab
            label={intl.formatMessage({ id: 'screen.investmentList.in_transit' })}
            {...getAriaAndId(InvestmentsListTab.IN_TRANSIT)}
          />
          <Tab
            label={intl.formatMessage({ id: 'screen.investmentList.draft' })}
            {...getAriaAndId(InvestmentsListTab.DRAFT)}
          />
          <Tab
            label={intl.formatMessage({ id: 'screen.investmentList.stopped' })}
            {...getAriaAndId(InvestmentsListTab.STOPPED)}
          />
          <Tab
            label={intl.formatMessage({ id: 'screen.investmentList.closing' })}
            {...getAriaAndId(InvestmentsListTab.CLOSING)}
          />
          <Tab
            label={intl.formatMessage({ id: 'screen.investmentList.closed' })}
            {...getAriaAndId(InvestmentsListTab.CLOSED)}
          />
        </MuiTabs>
      </TabsWrapper>
      <MainDataGridWrapper>
        <Typography variant="body2" color={theme.typographyColor.secondaryText}>
          <FormattedMessage
            id={`screen.investmentList.${(
              selectedTab.status as unknown as string
            ).toLowerCase()}.description`}
          />
        </Typography>
        <DataGrid
          columns={columns}
          rows={investments}
          rowCount={total}
          pagination
          page={page}
          pageSize={limit}
          paginationMode="server"
          onPageChange={handleChangePage}
          onPageSizeChange={handleChangePageSize}
          checkboxDisabled
          isLoading={loading}
          hasError={Boolean(error)}
          getRowId={(row) => row?.investment_uuid}
          autoHeight
          onRowClick={onRowClick}
          onSortModelChange={handleSortModelChange}
          activeSorting
          sortingMode="server"
          initialState={{
            sorting: {
              sortModel:
                orderBy && orderDirection ? [{ field: orderBy, sort: orderDirection }] : [],
            },
          }}
        />
      </MainDataGridWrapper>
    </TabContentWrapper>
  )
}

export default InvestmentList
