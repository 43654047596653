import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { InvestmentsListTab } from 'global/types'

export interface InvestmentsTab {
  status: InvestmentsListTab
  index: number
}

const initialState = { status: InvestmentsListTab.ACTIVE, index: 0 } as InvestmentsTab

const investmentsListTabStates: InvestmentsTab[] = [
  { status: InvestmentsListTab.ACTIVE, index: 0 },
  { status: InvestmentsListTab.IN_TRANSIT, index: 1 },
  { status: InvestmentsListTab.DRAFT, index: 2 },
  { status: InvestmentsListTab.STOPPED, index: 3 },
  { status: InvestmentsListTab.CLOSING, index: 4 },
  { status: InvestmentsListTab.CLOSED, index: 5 },
]

const investmentsListSlice = createSlice({
  name: 'investmentsList',
  initialState,
  reducers: {
    changeTab(state, action: PayloadAction<number>) {
      const newTab = investmentsListTabStates[action.payload]
      state.index = newTab.index
      state.status = newTab.status
    },
  },
})

export const { changeTab } = investmentsListSlice.actions
export default investmentsListSlice.reducer
